@import 'styles/variables';

.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.toolbar {
  border-bottom: 1px solid $gray-300;
  padding: 0.5rem;
}
.main {
  flex: auto;
  display: flex;
  overflow: auto;
}
.content {
  flex: 1;
  background-color: $gray-200;
  display: flex;
}
.contentInner {
  margin: auto;
}
.sidebar {
  padding: 1rem 1.5rem;
  border-left: 1px solid $gray-300;
}
.content {
  flex: 1;
  overflow: auto;
}
.footer {
  background-color: $blue-100;
  min-height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heart,
.link {
  margin: 0 0.5rem;
}
.heart {
  font-size: 0.75rem;
}
