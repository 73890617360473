.setup {
  background-color: #fff;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 0.5rem;
}
.formGroup {
  margin-bottom: 1rem;
}
.actions {
  display: flex;
  justify-content: end;
}
