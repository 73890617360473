.gradient {
  width: 100%;
  height: 1.25rem;
}
.domain {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  font-family: monospace;
}
