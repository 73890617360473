@import 'styles/variables';

.node {
  fill: $gray-600;
  opacity: 0.75;
}
.node:hover {
  filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5));
}
.hide {
  fill: none;
}
.active {
  fill: $orange;
  stroke: #ffffff;
  filter: drop-shadow(0 0 0.25rem $red);
}
.transparent {
  opacity: 0;
  fill: rgba(220, 53, 69, 0.25);
  transition: opacity 0.25s ease;
}
.transparent:hover {
  opacity: 1;
}
