.toolbar {
  display: flex;
  button {
    margin-right: 0.5rem;
    svg {
      vertical-align: -0.125rem;
    }
  }
}
.group {
  margin-right: 1rem;
}
